<template>
<div class="container">
    <div class="slider">

        <div class="box1 box">
            <div class="slider-bg"></div>
            <div class="details">
                <h1 class="slider-h1">Photobook</h1>
                <p class="slider-p">
                    Lo scrigno che conterrà le tue foto preferite, da custodire o da condividere con chi vuoi.
                </p>
                <!--<vs-button size="large" onclick="location.href='https://www.codehim.com'">Check Now</vs-button>-->
            </div>

            <div class="illustration">
                <img src="../assets/figures-4.1.png" style="max-width: 100%;" alt="" />
            </div>
        </div>

        <div class="box2 box">
            <div class="slider-bg"></div>
            <div class="details">
                <h1 class="slider-h1">Libro autobiografico</h1>
                <p class="slider-p">
                    Lo strumento perfetto per raccontare una storia… magari quella della tua vita.
                </p>
                <!--<button>Check Now</button>-->
            </div>

            <div class="illustration">
                <img src="../assets/figures-10.1.png" style="max-width: 100%;" alt="" />
            </div>
        </div>

        <div class="box3 box">
            <div class="slider-bg"></div>
            <div class="details">
                <h1 class="slider-h1">Magazine</h1>
                <p class="slider-p">
                    Una rivista dedicata a te che sfoglierai piacevolmente: i momenti più emozionanti della tua vita, le foto dei tuoi viaggi e le notizie più entusiasmanti non colmeranno mai queste pagine.
                </p>
                <!--<button>Check Now</button>-->
            </div>

            <div class="illustration">
                <img src="../assets/figures-5.1.png" style="max-width: 100%;" alt="" />
            </div>
        </div>

        

        <!--<div class="box4 box">
            <div class="slider-bg"></div>
            <div class="details">
                <h1 class="slider-h1">I'm the fourth Box</h1>
                <p class="slider-p">
                    Lorem ipsum dolor sit amet, consectetur adipiscing
                    elit. Integer lacinia dui lectus. Donec scelerisque ipsum
                    diam, ac mattis orci pellentesque eget.
                </p>
            </div>

            <div class="illustration">
                <div class="inner"></div>
            </div>
        </div>

        <div class="box5 box">
            <div class="slider-bg"></div>
            <div class="details">
                <h1 class="slider-h1">I'm the fifth Box</h1>
                <p class="slider-p">
                    Lorem ipsum dolor sit amet, consectetur adipiscing
                    elit. Integer lacinia dui lectus. Donec scelerisque ipsum
                    diam, ac mattis orci pellentesque eget.
                </p>
            </div>

            <div class="illustration">
                <div class="inner"></div>
            </div>
        </div>-->

    </div>

    <svg xmlns="http://www.w3.org/2000/svg" class="prev" width="56.898" height="91" viewBox="0 0 56.898 91">
        <path d="M45.5,0,91,56.9,48.452,24.068,0,56.9Z" transform="translate(0 91) rotate(-90)" fill="#fff" /></svg>
    <svg xmlns="http://www.w3.org/2000/svg" class="next" width="56.898" height="91" viewBox="0 0 56.898 91">
        <path d="M45.5,0,91,56.9,48.452,24.068,0,56.9Z" transform="translate(56.898) rotate(90)" fill="#fff" /></svg>
    <div class="trail">
        <div class="box1 active">1</div>
        <div class="box2">2</div>
        <div class="box3">3</div>
    </div>
</div>
</template>

<script>
import gsap from "gsap";

export default {
    name: 'product-slider',
    mounted() {
        // Slider(all Slides in a container)
        const slider = document.querySelector(".slider")
        // All trails 
        const trail = document.querySelector(".trail").querySelectorAll("div")

        // Transform value
        let value = 0
        // trail index number
        let trailValue = 0
        // interval (Duration)
        let interval = 5000

        // Function to slide forward
        const slide = (condition) => {
            // CLear interval
            clearInterval(start)
            // update value and trailValue
            condition === "increase" ? initiateINC() : initiateDEC()
            // move slide
            move(value, trailValue)
            // Restart Animation
            animate()
            // start interal for slides back 
            start = setInterval(() => slide("increase"), interval);
        }

        // function for increase(forward, next) configuration
        const initiateINC = () => {
            // Remove active from all trails
            trail.forEach(cur => cur.classList.remove("active"))
            // increase transform value
            value === 66.66 ? value = 0 : value += 33.33
            // update trailValue based on value
            trailUpdate()
        }

        // function for decrease(backward, previous) configuration
        const initiateDEC = () => {
            // Remove active from all trails
            trail.forEach(cur => cur.classList.remove("active"))
            // decrease transform value
            value === 0 ? value = 66.66 : value -= 33.33
            // update trailValue based on value
            trailUpdate()
        }

        // function to transform slide 
        const move = (S, T) => {
            // transform slider
            slider.style.transform = `translateX(-${S}%)`
            //add active class to the current trail
            trail[T].classList.add("active")
        }

        const tl = gsap.timeline({
            defaults: {
                duration: 0.6,
                ease: "power2.inOut"
            }
        })
        tl.from(".slider-bg", {
                x: "-100%",
                opacity: 0
            })
            .from(".slider-p", {
                opacity: 0
            }, "-=0.3")
            .from(".slider-h1", {
                opacity: 0,
                y: "30px"
            }, "-=0.3")
            

        // function to restart animation
        const animate = () => tl.restart()

        // function to update trailValue based on slide value
        const trailUpdate = () => {
            if (value === 0) {
                trailValue = 0
            } else if (value === 33.33) {
                trailValue = 1
            } else if (value === 66.66) {
                trailValue = 2
            } else if (value === 60) {
                trailValue = 3
            } else {
                trailValue = 4
            }
        }

        // Start interval for slides
        let start = setInterval(() => slide("increase"), interval)

        // Next  and  Previous button function (SVG icon with different classes)
        document.querySelectorAll("svg").forEach(cur => {
            // Assign function based on the class Name("next" and "prev")
            cur.addEventListener("click", () => cur.classList.contains("next") ? slide("increase") : slide("decrease"))
        })

        // function to slide when trail is clicked
        const clickCheck = (e) => {
            // CLear interval
            clearInterval(start)
            // remove active class from all trails
            trail.forEach(cur => cur.classList.remove("active"))
            // Get selected trail
            const check = e.target
            // add active class
            check.classList.add("active")

            // Update slide value based on the selected trail
            if (check.classList.contains("box1")) {
                value = 0
            } else if (check.classList.contains("box2")) {
                value = 33.33
            } else if (check.classList.contains("box3")) {
                value = 66.66
            }
            // update trail based on value
            trailUpdate()
            // transfrom slide
            move(value, trailValue)
            // start animation
            animate()
            // start interval
            start = setInterval(() => slide("increase"), interval)
        }

        // Add function to all trails
        trail.forEach(cur => cur.addEventListener("click", (ev) => clickCheck(ev)))
        
        // Mobile touch Slide Section
        const touchSlide = (() => {
            let start, move, change, sliderWidth

            // Do this on initial touch on screen
            slider.addEventListener("touchstart", (e) => {
                // get the touche position of X on the screen
                start = e.touches[0].clientX
                // (each slide with) the width of the slider container divided by the number of slides
                sliderWidth = slider.clientWidth / trail.length
            })

            // Do this on touchDrag on screen
            slider.addEventListener("touchmove", (e) => {
                // prevent default function
                // get the touche position of X on the screen when dragging stops
                move = e.touches[0].clientX
                // Subtract initial position from end position and save to change variabla
                change = start - move
            })

            const mobile = () => {
                // if change is greater than a quarter of sliderWidth, next else Do NOTHING
                change > (sliderWidth / 4) ? slide("increase") : null;
                // if change * -1 is greater than a quarter of sliderWidth, prev else Do NOTHING
                (change * -1) > (sliderWidth / 4) ? slide("decrease"): null;
                // reset all variable to 0
                [start, move, change, sliderWidth] = [0, 0, 0, 0]
            }
            // call mobile on touch end
            slider.addEventListener("touchend", mobile)
        });

        touchSlide();
    }
}
</script>

<style scoped>

.container {
  position: relative;
  overflow: hidden;
  border-radius: 5rem;
  width: 80%;
  margin: 0 auto;
}
@media only screen and (max-width: 1000px) {
  .container {
    border-radius: 0;
    width: 100%!important;
  }
}

.slider {
  display: flex;
  width: 300%;
  height: 40rem;
  transition: all 0.25s ease-in;
  transform: translateX(0);
}
@media only screen and (max-width: 1000px) {
  .slider {
    height: 100vh;
  }
  h1 {
    font-size: 42px!important;
  }
}
.slider .box {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  overflow: hidden;
  position: relative;

}
@media only screen and (max-width: 650px) {
  .slider .box {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr);
  }
}
.slider .box .slider-bg {
  padding: 2rem;
  background-color: rgba(0, 0, 0, 0.2);
  width: 55%;
  transform: skewX(7deg);
  position: absolute;
  height: 100%;
  left: -10%;
  padding-left: 20rem;
  transform-origin: 0 100%;
}
@media only screen and (max-width: 800px) {
  .slider .box .slider-bg {
    width: 65%;
  }

  .trail {
    display: none!important;
  }
}
@media only screen and (max-width: 650px) {
  .slider .box .slider-bg {
    width: 100%;
    left: 0;
    bottom: 0;
    height: 60%;
    transform: skewX(0deg);
  }
}
.slider .box .slider-bg::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: inherit;
  pointer-events: none;
  transform: skewX(10deg);
}
@media only screen and (max-width: 650px) {
  .slider .box .slider-bg::before {
    width: 120%;
    bottom: 0;
    transform: skewX(0deg);
  }
}


.slider .box .details {
  position: absolute;
  bottom:0;
  height: 55%;
  transform-origin: 0 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px;
}

@media only screen and (min-width: 650px) {
  .slider .box .details {
    width: 50%;
    left: 8%;
    height: 100%;
    justify-content: center;
  }
}

.slider .box .details h1 {
  font-size: 3.5rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
  margin-top: 0;
}
.slider .box .details p {
  font-size: 1.3rem;
  color: #dfdfdf;
  margin-bottom: 2rem;
  margin-right: 5rem;
}
@media only screen and (max-width: 800px) {
  .slider .box .details p {
    margin-right: 0;
  }
}

h1 {
    color: #fff;
}


.slider .box1 {
  background-color: rgb(201, 107, 255);
}
.slider .box1 .illustration .inner {
  background-color: #FF0077;
}
.slider .box1 .illustration .inner::after, .slider .box1 .illustration .inner::before {
  background-color: rgba(255, 0, 119, 0.4);
}

.slider .box2 {
    background-color: rgb(72, 246, 150);
}
.slider .box2 .illustration .inner {
  background-color: #0033FF;
}
.slider .box2 .illustration .inner::after, .slider .box2 .illustration .inner::before {
  background-color: rgba(0, 51, 255, 0.4);
}

.slider .box3 {
    background-color: rgb(201, 107, 255);
}
.slider .box3 .illustration .inner {
  background-color: #00FF44;
}
.slider .box3 .illustration .inner::after, .slider .box3 .illustration .inner::before {
  background-color: rgba(0, 255, 68, 0.4);
}

.slider .box4 {
  background-color: #554D00;
}
.slider .box4 .illustration .inner {
  background-color: #FF4E00;
}
.slider .box4 .illustration .inner::after, .slider .box4 .illustration .inner::before {
  background-color: rgba(255, 78, 0, 0.4);
}

.slider .box5 {
  background-color: rgb(182, 72, 246);
}
.slider .box5 .illustration .inner {
  background-color: #8000FF;
}
.slider .box5 .illustration .inner::after, .slider .box5 .illustration .inner::before {
  background-color: rgba(128, 0, 255, 0.4);
}

.slider .illustration {
  grid-column: 2/-1;
  grid-row: 1/-1;
  justify-self: center;
}
@media only screen and (max-width: 650px) {
  .slider .illustration {
    grid-row: 1/span 1;
    grid-column: 1/-1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.slider .illustration div {
  height: 25rem;
  width: 18rem;
  border-radius: 3rem;
  background-color: #FF0077;
  position: relative;
  transform: skewX(-10deg);
}
@media only screen and (max-width: 800px) {
  .slider .illustration div {
    height: 20rem;
    width: 15rem;
  }
}
.slider .illustration div::after, .slider .illustration div::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 3rem;
  top: 0;
  left: 0;
}
.slider .illustration div::after {
  transform: translate(4rem, -1rem);
}
.slider .illustration div::before {
  transform: translate(2rem, -2rem);
}

.prev,
.next,
.trail {
  z-index: 10000;
  position: absolute;
}

.prev,
.next {
  width: 4rem;
  cursor: pointer;
  opacity: 0.2;
  transition: all 0.3s ease;
}
@media only screen and (max-width: 650px) {
  .prev,
.next {
    display: none;
  }
}
.prev:hover,
.next:hover {
  opacity: 1;
}

.prev {
  top: 50%;
  left: 2%;
  transform: translateY(-50%);
}

.next {
  top: 50%;
  right: 2%;
  transform: translateY(-50%);
}

.trail {
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
  width: 30%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  text-align: center;
  font-size: 1.5rem;
  color: white;
}
@media only screen and (max-width: 650px) {
  .trail {
    width: 90%;
    bottom: 13%;
  }
}
.trail div {
  padding: 2rem;
  border-top: 3px solid #fff;
  cursor: pointer;
  opacity: 0.3;
  transition: all 0.3s ease;
}
.trail div:hover {
  opacity: 0.6;
}
@media only screen and (max-width: 650px) {
  .trail div {
    padding: 1rem;
  }
}

.active {
  opacity: 1 !important;
}
</style>